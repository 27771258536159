import { gql } from 'graphql-request';

export interface wizardsShownInterface {
	deliveryTerminalKey?: boolean;
	hasExplainedCommission?: boolean;
	tutorialHowToMakeAFreightRequest?: boolean;
	tutorialHowToMakeAnOffer?: boolean;
}

export interface AuthMeResponse {
	_id: string;
	firstName?: string;
	lastName?: string;
	auth0Id: string;
	email: string;
	phone?: string;
	emailVerified: boolean;
	phoneVerified: boolean;
	status: string;
	isAdmin?: boolean;
	betaUserFeatures?: Array<string>;
	isKeelspotPlus?: boolean;
	profilePhoto?: {
		_id: string;
		originalName: string;
		s3Key: string;
		mimetype: string;
		encoding: string;
		path: string;
	};
	roles?: Array<{ _id: string; name: string; slug: string; level: number }>;
	operations?: Array<{ _id: string; name: string; slug: string; level: number }>;
	company?: {
		_id: string;
		name: string;
		organisationNumber: string;
		owner: string;
		isKeelspotPlus: boolean;
		address?: {
			_id: string;
			master: string;
			addressLine: string;
			city: string;
			region: string;
			country: string;
			postalCode: string;
		};
	};
	acceptTermsAndConditions?: boolean;
	wizardsShown?: wizardsShownInterface;
	remainingStep: {
		personal: boolean;
		operation: boolean;
		company: boolean;
	};
	notifyFRDomestic: boolean;
	notifyFRInternational: boolean;
	notifyFRProject: boolean;
	notifyFRPartial: boolean;
	notifyFRContainer: boolean;
	notifyFRBulk: boolean;
	notifyCapacity: boolean;
}
export const AUTH_ME = gql`
	query authMe {
		authMe {
			message
			data {
				_id
				firstName
				lastName
				email
				phone
				emailVerified
				isLoggedIn
				isAdmin
				betaUserFeatures
				roles {
					_id
					name
					slug
				}
				company {
					_id
					name
					isVerified
					isKeelspotPlus
					primaryOperation {
						slug
					}
				}
				operations {
					_id
					name
					slug
				}
				remainingStep {
					personal
					operation
					company
				}
				wizardsShown {
					deliveryTerminalKey
					hasExplainedCommission
					tutorialHowToMakeAFreightRequest
					tutorialHowToMakeAnOffer
				}
				notifyFRDomestic
				notifyFRInternational
				notifyFRProject
				notifyFRPartial
				notifyFRContainer
				notifyFRBulk
				notifyCapacity
			}
		}
	}
`;

export const VIEW_OWN_COMPANY = gql`
	query viewCompany {
		viewOwnCompany {
			message
			data {
				_id
				name
				organisationNumber
				address {
					_id
					addressLine
					city
					region
					country
					postalCode
				}
				profilePhoto {
					_id
					path
					mimetype
					s3Key
				}
				createdAt
				updatedAt
			}
		}
	}
`;

export const GET_COMPANY_BY_ID = gql`
	query getCompanyById($id: String) {
		getCompanyById(id: $id) {
			message
			data {
				_id
				name
				organisationNumber
				address {
					_id
					addressLine
					city
					region
					country
					postalCode
				}
				profilePhoto {
					_id
					path
					mimetype
					s3Key
				}
				createdAt
				updatedAt
			}
		}
	}
`;

export const INVITED_MEMBER = gql`
	query listInvitedMember {
		listInvitedMember {
			message
			data {
				_id
				email
				firstName
				lastName
				status
				profilePhoto {
					_id
					path
					mimetype
				}
				roles {
					_id
					name
					slug
				}
			}
		}
	}
`;

export const GET_ALL_COMPANIES = gql`
	query getAllCompanies {
		getAllCompanies {
			message
			data {
				_id
				name
				isBluecheck
				profilePhoto {
					_id
					path
					mimetype
				}
			}
		}
	}
`;

export const GET_ALL_SHIPPING_COMPANIES = gql`
	query getAllShippingCompanies {
		getAllShippingCompanies {
			message
			data {
				_id
				name
				isBluecheck
			}
		}
	}
`;

export interface GetWizardsShownResponse {
	wizardsShown: null | wizardsShownInterface;
}

export const GET_WIZARDS_SHOWN = gql`
	query getWizardsShown {
		getWizardsShown {
			message
			data {
				_id
				wizardsShown {
					deliveryTerminalKey
					hasExplainedCommission
					tutorialHowToMakeAFreightRequest
					tutorialHowToMakeAnOffer
				}
			}
		}
	}
`;
