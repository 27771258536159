// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import { memo, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, useLocation } from 'react-router-dom';
import { PENDING_CONFIRM_EMAIL } from '@/config/path';
import { useAuthMe } from '@/api/auth';
import Loader from '@components/loader';
import useRouteChecker from '@hooks/useRouteChecker';
import { useClient } from '@/context/GraphqlRequestContext';
import { useCreateViewClick } from '@/api/viewclick';
import { isInDemoMode } from '@/utils/isInDemoMode';

const GraphQlAuthWrapperBase = ({
	children,
	originalPathname,
	isAuthorized,
}: PropsWithChildren<{ originalPathname: string; isAuthorized: boolean }>) => {
	const { data, isFetching: authorizing } = useAuthMe(isAuthorized);
	const { pathname } = useLocation();
	const { user } = useAuth0();

	const { isAuthPage, isPublicPage } = useRouteChecker({
		pathname,
	});

	const hasSomeStepRemaining = useMemo(() => {
		if (!data) {
			return false;
		}
		return Object.values(data.remainingStep).some((state) => !state);
	}, [data]);

	const preventUserToAccess = useMemo(() => {
		if (!data) {
			return false;
		}
		if (data?.company?.isVerified) {
			return false;
		}
		const email = user?.email?.split('@');
		return email ? ['gmail.com', 'hotmail.com', 'yahoo.com'].includes(email[1]) : true;
	}, [data]);

	if (isPublicPage) {
		return <>{children}</>;
	}

	// In the onboarding process authorizing is true and user is null, very hacky but works.
	// TODO: refactor this and the entire onboarding process and user registration
	if (user && authorizing && pathname.startsWith('/landing')) {
		return <>{children}</>;
	}

	if (authorizing) {
		return <Loader />;
	}

	// Redirect to onboard steps
	if (!data && user?.sub && !isAuthPage) {
		return <Navigate to='/landing/register' state={{ from: originalPathname, data }} replace />;
	}

	// Redirect to onboard steps
	if (!isAuthPage && data && hasSomeStepRemaining) {
		return <Navigate to='/landing/register' state={{ from: originalPathname, data }} replace />;
	}

	if (
		!isInDemoMode &&
		!hasSomeStepRemaining &&
		preventUserToAccess &&
		data &&
		!pathname.includes(PENDING_CONFIRM_EMAIL)
	) {
		return (
			<Navigate
				to={`/landing/${PENDING_CONFIRM_EMAIL}`}
				state={{ from: originalPathname, data }}
				replace
			/>
		);
	}
	if (!preventUserToAccess && !hasSomeStepRemaining && pathname.includes(PENDING_CONFIRM_EMAIL)) {
		return <Navigate to='/' state={{ from: originalPathname, data }} replace />;
	}

	return <>{children}</>;
};

const Auth0WrapperBase = ({
	children,
	originalPathname,
}: PropsWithChildren<{ originalPathname: string }>) => {
	const { pathname } = useLocation();
	const { isAuthPage, isPublicPage } = useRouteChecker({
		pathname,
	});
	const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
	const { hasAuthorizationHeader } = useClient();

	const { mutate: createViewClick } = useCreateViewClick();
	useEffect(() => {
		if (isAuthenticated && hasAuthorizationHeader) {
			createViewClick({ input: { type: 'app_load', click: false } });
		}
	}, [createViewClick, isAuthenticated, hasAuthorizationHeader]);

	if (isPublicPage) {
		return <>{children}</>;
	}

	if (isLoading || (isAuthenticated && !hasAuthorizationHeader)) {
		return <Loader />;
	}

	if (!isAuthenticated && !isAuthPage) {
		localStorage.setItem('redirect', pathname);
		return loginWithRedirect({
			redirectUri: `${window.location.origin}`,
			appState: { returnTo: originalPathname },
		});
	}

	return (
		<GraphQlAuthWrapper
			originalPathname={originalPathname}
			isAuthorized={isAuthenticated && hasAuthorizationHeader}
		>
			{children}
		</GraphQlAuthWrapper>
	);
};

const GraphQlAuthWrapper = memo(GraphQlAuthWrapperBase);

const Auth0Wrapper = memo(Auth0WrapperBase);

function IndexLayout({ children }: PropsWithChildren) {
	const { pathname } = useLocation();
	const [landingPath] = useState(() => pathname);

	return <Auth0Wrapper originalPathname={landingPath}>{children}</Auth0Wrapper>;
}

export default memo(IndexLayout);
