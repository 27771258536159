import { useEffect, useState, useRef, useCallback } from 'react';

import { QUERY_CLIENT } from '@/config';
import useIsMountedRef from './useIsMountedRef';
import { UserMeta } from '@/components/Chat/Chat';
import { OperationEnum } from '@/enums/OperationEnum';

// ----------------------------------------------------------------------

export default function useGetCurrentUser() {
	const [currentUser, setCurrentUser] = useState<UserMeta | undefined>();
	const [hasFetched, setHasFetched] = useState(false);
	const userRef = useRef<any>();
	const isMountedRef = useIsMountedRef();

	useEffect(() => {
		if (!hasFetched && isMountedRef) {
			setHasFetched(true);
			userRef.current = QUERY_CLIENT.getQueryCache().find({ queryKey: ['authMe'] } as any);
		}
	}, [isMountedRef, hasFetched, userRef]);

	useEffect(() => {
		const currentUserData = userRef.current && (userRef.current?.state.data as any);
		if (currentUserData) {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const {
				_id,
				firstName,
				lastName,
				roles,
				company,
				operations,
				isAdmin,
				notifyFRDomestic,
				notifyFRInternational,
				notifyFRBulk,
				notifyFRProject,
				notifyFRPartial,
				notifyFRContainer,
				notifyCapacity,
				...rest
			} = currentUserData;

			setCurrentUser({
				...rest,
				name: `${firstName} ${lastName}`,
				role: 'default',
				id: _id,
				owner: company?._id,
				operation:
					company?.primaryOperation?.slug === OperationEnum.FREIGHT_FORWARDER
						? OperationEnum.FREIGHT_FORWARDER
						: operations[0]?.slug,
				company: company?.name,
				companyPrimaryOperationSlug: company?.primaryOperation?.slug,
				companyVerified: company?.isVerified,
				isAdmin: isAdmin,
				isKeelspotPlus: company?.isKeelspotPlus,
				notifyFRDomestic: notifyFRDomestic,
				notifyFRInternational: notifyFRInternational,
				notifyFRProject: notifyFRProject,
				notifyFRPartial: notifyFRPartial,
				notifyFRContainer: notifyFRContainer,
				notifyFRBulk: notifyFRBulk,
				notifyCapacity: notifyCapacity,
			});
		}
	}, [userRef]);

	const setOwner = useCallback(
		(newOwnerId: any) => {
			if (currentUser) {
				setCurrentUser({
					...currentUser,
					owner: newOwnerId,
				});
			}
		},
		[currentUser],
	);

	return { currentUser, setOwner };
}
