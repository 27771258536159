import { useEffect } from 'react';
import useGetCurrentUser from '@/hooks/useGetCurrentUser';
import { APP, CARRIERS, MAP } from '@/config/path';
import { OperationEnum } from '@/enums/OperationEnum';
import { useNavigate } from 'react-router-dom';
import { Loader } from './Loader';

export function InitialLoader() {
	const { currentUser } = useGetCurrentUser();
	const navigate = useNavigate();

	useEffect(() => {
		if (!currentUser) {
			return;
		}

		if (currentUser.operation === OperationEnum.SHIP_OPERATOR) {
			navigate(`${APP}/${CARRIERS.INDEX}`);
		} else {
			navigate(`${APP}/${MAP}`);
		}
	}, [currentUser, navigate]);

	return <Loader />;
}

export default InitialLoader;
