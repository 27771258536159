import { gql } from 'graphql-request';

const QUAY = `{
	_id
	locationName
	unlocode
}`;

const PORT = `{
	_id
	uuid
	countryIso
	portName: name
	portType
	geographic {
		type
		coordinates
	}
}`;

const CARGOS = `{
	_id
	cargoType
	product
	quantity
	containerType
	grossWeight
	netWeight
	measurementUnit
	stackable
	lift
	dimensions {
		length
		width
		height
	}
	shipType
	loadingRate
	dischargeRate
	rateUnit
}`;

export const LIST_SHIPMENTS = gql`
	query listShipments($status: ShipmentStatusEnumType, $owner: String, $query: String) {
		listShipments(status: $status, owner: $owner, query: $query) {
			message
			data {
				_id
				name
				matchmaking {
					status
					_id
				}
				targetPrice
				targetPriceCurrency
				adcom
				isPrivate
				companyList {
					name
					_id
				}
				owner {
					name
					_id
				}
				offersCount
				routes {
					_id
					shipmentId
					duration_ms
					speed_kts
					distance_m
					points
				}
				offers {
					status
					_id
				}
				loadingPort {
					_id
					countryIso
					portName: name
					type
					geographic {
						type
						coordinates
					}
				}
				readinessDate
				bidDeadlineDate
				status
				freights {
					_id
					owner {
						name
						_id
					}
					equipmentAndServices {
						crane
						truck
						excavator
						forklift
						tug
					}
					name
					billOfLandingNumber
					hazardous
					imoClass
					unNumber
					notice
					sender {
						name
						email
						telephoneNumber
						address {
							_id
							addressLine
							city
							region
							country
							postalCode
						}
					}
					receiver {
						name
						email
						telephoneNumber
						address {
							_id
							addressLine
							city
							region
							country
							postalCode
						}
					}
					cargos {
						_id
						cargoType
						product
						quantity
						containerType
						grossWeight
						netWeight
						measurementUnit
						stackable
						lift
						dimensions {
							length
							width
							height
						}
						shipType
						loadingRate
						dischargeRate
					}
					readinessDate
					dischargeDate
					isPublished
					status
					dischargedPort {
						_id
						portName: name
						type
						geographic {
							type
							coordinates
						}
					}
					loadingPort {
						_id
						portName: name
						type
						geographic {
							type
							coordinates
						}
					}
				}
			}
		}
	}
`;

export const LIST_OWN_SHIPMENT = gql`
	query listOwnShipments($status: ShipmentStatusEnumType, $query: String) {
		listOwnShipments(status: $status, query: $query) {
			message
			data {
				_id
				name
				owner
				isPrivate
				companyList {
					name
					_id
				}
				offers {
					_id
					currency
					amount
					expireDate
					loadingDate
					status
					offerFrom {
						_id
						name
					}
					freights {
						_id
						alternativeDischargeDate
					}
					acceptOffers {
						_id
						accepted
						actor
						operation
					}
					vessel {
						_id
						name
					}
				}
				createdBy {
					firstName
					lastName
					email
					phone
					_id
					profilePhoto {
						_id
						mimetype
						path
						s3Key
					}
				}
				createdAt
				offersCount
				routes {
					_id
					shipmentId
					points
					duration_ms
					speed_kts
					distance_m
				}
				loadingPort {
					_id
					portName: name
					geographic {
						type
						coordinates
					}
					type
				}
				readinessDate
				bidDeadlineDate
				status
				freights {
					_id
					owner {
						name
					}
					name
					billOfLandingNumber
					hazardous
					imoClass
					unNumber
					notice
					sender {
						name
						email
						telephoneNumber
						address {
							_id
							addressLine
							city
							region
							country
							postalCode
						}
					}
					receiver {
						name
						email
						telephoneNumber
						address {
							_id
							addressLine
							city
							region
							country
							postalCode
						}
					}
					cargos {
						_id
						cargoType
						product
						quantity
						containerType
						grossWeight
						netWeight
						measurementUnit
						stackable
						lift
						dimensions {
							length
							width
							height
						}
						shipType
						loadingRate
						dischargeRate
					}
					readinessDate
					dischargeDate
					isPublished
					status
					dischargedPort {
						_id
						countryIso
						portName: name
						type
						geographic {
							type
							coordinates
						}
					}
					loadingPort {
						_id
						countryIso
						portName: name
						type
						geographic {
							type
							coordinates
						}
					}
					manuallyInformedLoadingQuay
					manuallyInformedDischargeQuay
					loadingQuay {
						_id
						locationName
						unlocode
					}
					dischargeQuay {
						_id
						locationName
						unlocode
					}
				}
			}
		}
	}
`;

export const GET_EACH_SHIPMENT = gql`
	query shipment($id: String!) {
		shipment(_id: $id) {
			message
			data {
				_id
				name
				co2e_total_g
				co2e_total_road_g
				targetPrice
				targetPriceCurrency
				adcom
				companyListId
				isPrivate
				owner {
					name
					_id
					isBluecheck
					creditPercent
					creditGrade
					creditDescription
					comissionPercentage
					profilePhoto {
						_id
						s3Key
						mimetype
						encoding
						originalName
						fullPath
					}
				}
				routes {
					_id
					shipmentId
					points
					duration_ms
					speed_kts
					distance_m
				}
				routeByRoad {
					_id
					points
					distance_m
				}
				createdAt
				updatedAt
				loadingPort {
					_id
					areaLvl1,
					areaLvl2,
					uuid
					countryIso
					portName: name
					unlocode
					countryName
					countryIso
					geographic {
						type
						coordinates
					}
				}
				readinessDate
				bidDeadlineDate
				status
				freights {
					_id
					attachments {
						_id
						s3Key
						mimetype
						encoding
						originalName
						path
					}
					owner {
						name
					}
					name
					billOfLandingNumber
					hazardous
					imoClass
					unNumber
					notice
					invoiceReference
					manuallyInformedLoadingQuay
					manuallyInformedDischargeQuay
					senderNotify
					receiverNotify
					importerNotify
					exporterNotify
					incoterm
					loadingQuay {
						_id
						locationName
						unlocode
					}
					dischargeQuay {
						_id
						locationName
						unlocode
					}
					importer {
						_id
						name
						email
						telephoneNumber
						address {
							_id
							addressLine
							city
							region
							country
							postalCode
						}
					}
					exporter {
						_id
						name
						email
						telephoneNumber
						address {
							_id
							addressLine
							city
							region
							country
							postalCode
						}
					}
					customerNotify {
						_id
						name
						email
						telephoneNumber
						address {
							_id
							addressLine
							city
							region
							country
							postalCode
						}
					}
					sender {
						_id
						name
						email
						telephoneNumber
						address {
							_id
							addressLine
							city
							region
							country
							postalCode
						}
					}
					receiver {
						_id
						name
						email
						telephoneNumber
						address {
							_id
							addressLine
							city
							region
							country
							postalCode
						}
					}
					cargos {
						_id
						cargoType
						product
						quantity
						containerType
						grossWeight
						stackable
						lift
						dimensions {
							length
							width
							height
						}
						shipType
						loadingRate
						dischargeRate
						netWeight
						measurementUnit
						rateUnit
					}
					readinessDate
					dischargeDate
					isPublished
					status
					dischargedPort {
						_id
						uuid
						countryIso
						portName: name
						unlocode
						countryName
						state
						city
						municipality
						postcode
						portType
						areaLvl1
						areaLvl2
						type
						geographic {
							type
							coordinates
						}
					}
					loadingPort {
						_id
						uuid
						countryIso
						unlocode
						portName: name
						countryName
						state
						city
						municipality
						postcode
						portType
						areaLvl1
						areaLvl2
						type
						geographic {
							type
							coordinates
						}
					}
					equipmentAndServices {
						crane
						truck
						excavator
						forklift
						tug
					}
					loadingTerms
					dischargeTerms
					shippingTerms
				}
				createdBy {
					_id
					firstName
					lastName
					email
					phone
					profilePhoto {
						_id
						mimetype
						path
						s3Key
					}
				}
				updatedBy {
					_id
					firstName
					lastName
					email
					phone
					profilePhoto {
						_id
						mimetype
						path
						s3Key
					}
				}
				offers {
					_id
					currency
					amount
					expireDate
					loadingDate
					status
					createdAt
					timeSinceCreated
          bidHasExpired
					co2e_total_g
					co2e_total_road_g
					transportBySea
					offerFrom {
						_id
						name
						isBluecheck
						creditPercent
						creditGrade
						creditDescription
						profilePhoto {
							_id
							mimetype
							path
							s3Key
						}
					}
					createdBy {
						_id
						firstName
						lastName
						profilePhoto {
							_id
							mimetype
							path
							s3Key
						}
					}
					freights {
						_id
						alternativeDischargeDate
						alternativeDischargeQuay ${QUAY}
						alternativeManuallyInformedDischargeQuay
						alternativeDischargedPort ${PORT}
						owner {
							_id
							name
						}
						name
						billOfLandingNumber
						hazardous
						imoClass
						unNumber
						notice
						manuallyInformedLoadingQuay
						manuallyInformedDischargeQuay
						loadingQuay ${QUAY}
						dischargeQuay ${QUAY}
						cargos ${CARGOS}
						readinessDate
						dischargeDate
						isPublished
						status
						dischargedPort ${PORT}
						loadingPort ${PORT}
					}
					acceptOffers {
						_id
						accepted
						actor
						operation
					}
					vessel {
						_id
						name
					}
				}
			}
		}
	}
`;
