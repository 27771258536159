import { Fragment } from 'react';
import Card from '@/components/Card';
import { CreditScore } from './CreditScore';
import { BlueCheck } from '@/components/BlueCheck';
import { UndisclosedExplanation } from './UndisclosedExplanation';

export type SizeType = 'sm' | 'md' | 'lg';

type CompanyNameProps = {
	companyName: string | React.ReactNode;
	undisclosed: boolean;
	isBluechecked: boolean;
	size?: SizeType;
	creditScore?: number;
	creditGrade?: string;
	creditDescription?: string;
	profileImage?: string;
	contactName?: string | React.ReactNode;
	undisclosedText?: string;
	label?: string | React.ReactNode;
};

interface CompanyInfoType extends CompanyNameProps {
	label?: string;
	withCard?: boolean;
}

const ProfileImage = ({ profileImage, size }: { profileImage?: string; size?: SizeType }) => {
	const getImageSize = () => {
		if (size === 'sm') {
			return 'h-6 w-6';
		}
		if (size === 'md') {
			return 'h-12 w-12';
		}
		return 'h-20 w-20';
	};
	if (!profileImage) {
		return (
			<div
				className={`${getImageSize()} rounded-full bg-gray-100 border-1 border-solid border-keelspot-border-light`}
			/>
		);
	}
	return (
		<img
			src={profileImage}
			className={`${getImageSize()} object-contain rounded-full bg-gray-100 border-1 border-solid border-keelspot-border-light`}
			alt=''
		/>
	);
};

export const CompanyName = ({
	companyName,
	undisclosed,
	isBluechecked,
	size,
	creditScore,
	creditGrade,
	creditDescription,
	profileImage,
	contactName,
	undisclosedText,
	label,
}: CompanyNameProps) => {
	const getTextSize = () => {
		if (size === 'sm') {
			return 'text-sm';
		}
		if (size === 'md') {
			return 'text-lg';
		}
		return 'text-2xl';
	};

	return (
		<div className='flex gap-2 items-center'>
			{profileImage && <ProfileImage profileImage={profileImage} size={size} />}
			<div className='flex flex-col -gap-1'>
				{label && <span className='text-sm text-keelspot-text-secondary'>{label}</span>}
				<div className='flex flex-wrap items-center gap-1'>
					<span className={`-my-[2px] font-semibold ${getTextSize()}`}>
						{undisclosed ? undisclosedText || 'Company Name Withheld*' : companyName}
					</span>
					<BlueCheck isBluecheck={isBluechecked} />
					{creditScore ? (
						<>
							<span className={`text-[13px] text-keelspot-border-light flex mx-1`}>|</span>
							<CreditScore
								size={'sm'}
								creditScore={Number(creditScore)}
								creditGrade={creditGrade}
								creditDescription={creditDescription}
							/>
						</>
					) : null}
				</div>
				{(undisclosed || contactName) && (
					<div className='flex gap-1 items-center'>
						{undisclosed && <UndisclosedExplanation />}
						{contactName && <span className='text-xs text-keelspot-text-secondary'>{contactName}</span>}
					</div>
				)}
			</div>
		</div>
	);
};

export const CompanyInfo = ({
	profileImage,
	contactName,
	companyName,
	isBluechecked,
	creditScore,
	creditGrade,
	creditDescription,
	label,
	undisclosed,
	withCard,
	size: size = 'md',
}: CompanyInfoType) => {
	const Wrapper = withCard ? Card : Fragment;

	return (
		<Wrapper>
			<div className={`flex gap-${size === 'lg' ? 8 : 4} items-center`}>
				{profileImage ? (
					<ProfileImage profileImage={profileImage} size={size} />
				) : (
					<ProfileImage size={size} />
				)}

				<CompanyName
					label={label}
					companyName={companyName}
					contactName={contactName}
					isBluechecked={isBluechecked}
					undisclosed={undisclosed}
					size={size}
					creditScore={size !== 'lg' ? creditScore : undefined}
					creditGrade={creditGrade}
					creditDescription={creditDescription}
				/>

				{size === 'lg' && creditScore && (
					<div>
						<CreditScore
							size={size}
							creditScore={creditScore}
							creditGrade={creditGrade}
							creditDescription={creditDescription}
						/>
					</div>
				)}
			</div>
		</Wrapper>
	);
};
